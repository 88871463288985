import { Directive, effect, inject, input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserAccessService } from '@ikp/service/user-access/user-access.service';

/**
 * Directive to show or hide an element based on the current user's permissions to access a certain path.
 * For configuration of permissions and paths see UserAccessService and `route-permissions.config.ts`.
 * Usage:
 * ```html
 * <div *ikpIfHasPermissionForPath="'some/restricted/path'">This is only visible if the path is accessible for the user</div>
 * ```
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ikpIfHasPermissionForPath]',
  standalone: true,
})
export class PathAccessPermissionsDirective {
  private userAccessService = inject(UserAccessService);
  private templateRef: TemplateRef<any> = inject(TemplateRef);
  private viewContainer: ViewContainerRef = inject(ViewContainerRef);

  ikpIfHasPermissionForPath = input.required<string>();

  private changeElementVisibility = effect(() => {
    const hasAccess = this.userAccessService.hasUserAccessAsSignal(this.ikpIfHasPermissionForPath());
    if (hasAccess()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  });
}
